import { createStepsCompiler } from '@penbox-io/pnbx-smart-form'
import { FlowCompilerCached, evalFlowSteps, evalPages } from '@penbox-io/pen-script'
import {
  createRequestScope,
  extractFlowLocales,
  metaOption,
  negotiateFlowLocale,
} from '@penbox-io/smart-form'
import { hasOwn, ifString } from '@penbox-io/stdlib'

/** @typedef {import('./state').State} RequestState */

import { getValue } from './util'

const isActivable = (s) => !s.disabled
const isDisabled = (s) => s.disabled
const isActive = (s) => s.active
const isCommitted = (s) => s.committed
const isNotCommitted = (s) => !s.committed

const isDirty = (e) => e.touched
const isValid = (e) => (e.valid ?? true) && !e.error

const keyedToAttrs = (values) => {
  const attr = { data: {}, user: {} }

  for (const key in values) {
    const [obj, prop] = key.split('.', 2)

    // Fool proof
    if (!hasOwn(attr, obj)) continue

    attr[obj][prop] = values[key]
  }

  return attr
}

const isNotCardElement = (element) => element.type !== 'card'

const asPreviousResponseStep = (step) => ({
  ...step,
  elements: step.elements?.filter(isNotCardElement)?.map((element) => ({
    ...element,
    title: element?.options?.title ?? element.title,
  })),
})

const getOption = (scope, name) => (scope ? metaOption(scope, name) : undefined)

const encodeFile = (file) => ({
  size: file.size,
  type: file.type || undefined,
  name: file.name,

  id: file.id,
  metadata: file.metadata,
})

const isInvalidFile = (item) => item instanceof File && !item.id

const sanitizeValue = (value) => {
  if (Array.isArray(value)) {
    return value
      .filter((item) => !isInvalidFile(item))
      .map((item) => (item instanceof File ? encodeFile(item) : item))
  }

  if (value instanceof File) return isInvalidFile(value) ? null : encodeFile(value)
  return value
}

export default {
  // Methods

  stepAttr: (state, getters) => {
    return (step, { withDefaults = true } = {}) => {
      // Because flows may contain weird logic, the currently displayed fields may
      // depend on other fields that are not visible anymore. In order to be
      // consistent with what the user sees when he fills the form, we will make
      // sure that every pending value corresponding to a hidden field is
      // accounted for (ghost data).

      // All the ghost data...
      const attr = keyedToAttrs(getters.pendingGhosts)

      // ...plus (default) values from current step.
      if (step) {
        for (const element of step.elements) {
          if (element.key) {
            const [obj, prop] = element.key.split('.', 2)

            // Fool proof
            if (!hasOwn(attr, obj)) continue

            if (element.value !== undefined) attr[obj][prop] = sanitizeValue(element.value)
            else if (withDefaults) attr[obj][prop] = sanitizeValue(element.default ?? null)
          }
        }
      }

      return attr
    }
  },

  // Properties

  error: (state) => state.error,
  loading: (state) => state.loading === true,
  authenticating: (state) => state.access != null,
  authenticated: (state) =>
    state.access
      ? state.access.attributes.verified_at != null &&
        state.access.attributes.invalidated_at == null
      : null,
  authentifiable: (state) =>
    state.access
      ? state.access.attributes.verified_at == null &&
        state.access.attributes.invalidated_at == null
      : null,
  accessLocale: (state) => state.accessLocale,
  accessOptions: (state) => state.accessOptions,
  accessBranding: (state) => state.accessBranding,
  accessInactiveOrArchivedRequest: (state) => state.accessInactiveOrArchivedRequest,
  accessMethod: (state) =>
    state.access ? state.accessOptions?.[state.access.attributes.method] : null,
  token: (state, getters) => state.access?.attributes.$token ?? null,
  loaded: (state, getters) => Boolean(state.flow && state.request && getters.authenticated),
  requestId: (state, getters) => getters.request?.id ?? state.requestId,
  responseId: (state, getters) => (state.request ? state.response?.id ?? null : undefined),

  // Status
  completing: (state, getters) =>
    state.response ? state.response.attributes.completed_at == null : undefined,
  completed: (state, getters) =>
    state.response ? state.response.attributes.completed_at != null : undefined,
  // Note: "completed" has logical precedence over "declined"
  declined: (state, getters) =>
    state.response ? state.response.attributes.declined_at != null : undefined,
  rating: (state, getters) =>
    state.response ? state.response.meta['pnbx:feedback:rating'] ?? null : undefined,

  // Branding
  branding: (state, getters) =>
    state.branding?.attributes ?? state.company?.attributes ?? getters.accessBranding,

  colors: (state, getters) => getters.branding?.colors,
  companyName: (state, getters) => getters.branding?.name,
  companyEmail: (state, getters) => getters.branding?.contact?.email?.main,
  companyPhone: (state, getters) => getters.branding?.contact?.phone?.main,
  companyLinks: (state) => metaOption({ company: state.company }, 'pnbx:portal:links'),
  poweredByPenbox: (state) => metaOption({ company: state.company }, 'pnbx:powered-by-penbox'),
  favicon: (state, getters) => getters.branding?.favicon,
  homepage: (state, getters) => getters.branding?.links?.homepage,
  hostname: (state, getters) => getters.branding?.hostname,
  icon: (state, getters) => getters.branding?.icon,
  logo: (state, getters) => getters.branding?.logo,
  title: (state, getters) => getters.branding?.name,
  locales: (state) => extractFlowLocales(state.flow),
  pages: (state, getters) => evalPages(getters.scope, state.flow?.attributes.pages),
  skippable: (state, getters) =>
    state.flow?.meta['pnbx:skippable'] ?? state.flow_customization?.meta['pnbx:skippable'] ?? true,

  // - Options support old and new meta (pages)
  isMetaMigratedToPages: (state, getters) => {
    const flow = getters.entities?.flow
    return flow?.meta['pnbx:pages-migrated']
  },

  defaultLocale: (state) => state.flow?.attributes.locale,
  welcomePage: (state, getters) => {
    if (getters.isMetaMigratedToPages) {
      const flow = getters.entities?.flow

      if (!flow) return

      const pages = getters.pages

      return {
        enabled: pages?.welcome?.enabled, // @not used
        title: pages?.welcome?.options?.title,
        message: pages?.welcome?.options?.message,
        image: pages?.welcome?.options?.image,
        start: pages?.welcome?.options?.['start-button'],
        showPrivacyPolicy: pages?.welcome?.options?.['show-privacy-policy'],
        customPolicy: pages?.welcome?.options?.['custom-privacy-policy'],
        showHelpBtn: pages?.welcome?.options?.['show-help-button'],
        helpCustomText: pages?.welcome?.options?.['help-text'],
      }
    } else {
      return {
        title: getters.welcomeTitle,
        message: getters.welcomeMessage,
        image: getters.welcomeImage,
        start: getters.welcomeStart,
        showPrivacyPolicy: getters.privacyPolicy,
        customPolicy: getters.customPolicy,
        showHelpBtn: getters.showHelpBtn,
        helpCustomText: getters.helpCustomText,
      }
    }
  },

  submissionPage: (state, getters) => {
    if (getters.isMetaMigratedToPages) {
      const flow = getters.entities?.flow

      if (!flow) return

      const pages = getters.pages

      return {
        enabled: pages?.submit?.enabled, // autoSubmit
        validTitle: pages?.submit?.options?.['valid-title'],
        validImage: pages?.submit?.options?.['valid-image'],
        validMessage: pages?.submit?.options?.['valid-message'],
        validSubmitButton: pages?.submit?.options?.['valid-submit-button'],
        validModifyButton: pages?.submit?.options?.['valid-modify-button'],
        validConfirmationMessage: pages?.submit?.options?.['valid-confirmation-message'],
        invalidTitle: pages?.submit?.options?.['invalid-title'],
        invalidImage: pages?.submit?.options?.['invalid-image'],
        invalidMessage: pages?.submit?.options?.['invalid-message'],
        invalidButton: pages?.submit?.options?.['invalid-button'],
        blockedIf: pages?.submit?.options?.['blocked-if'],
        blockedTitle: pages?.submit?.options?.['blocked-title'],
        blockedMessage: pages?.submit?.options?.['blocked-message'],
      }
    } else {
      return {
        enabled: !getters.autoSubmit,
        validTitle: getters.completeTitle,
        validImage: getters.completeImage,
        validMessage: getters.completeMessage,
        validSubmitButton: getters.completeSend,
        validModifyButton: getters.completeModify,
        validConfirmationMessage: getters.confirmationText,
        invalidTitle: getters.incompleteTitle,
        invalidImage: getters.incompleteImage,
        invalidMessage: getters.incompleteMessage,
        invalidButton: getters.incompleteAction,
      }
    }
  },

  endingPage: (state, getters) => {
    if (getters.isMetaMigratedToPages) {
      const flow = getters.entities?.flow

      if (!flow) return

      const pages = getters.pages

      return {
        enabled: pages?.ending?.enabled,
        title: pages?.ending?.options?.title,
        message: pages?.ending?.options?.message,
        image: pages?.ending?.options?.image,
        rateUx: pages?.ending?.options?.['rate-ux'],
        redirectUrl: pages?.ending?.options?.['redirect-url'],
      }
    } else {
      return {
        enabled: getters.autoRedirect,
        title: getters.endedTitle,
        message: getters.endedMessage,
        image: getters.endedImage,
        rateUx: getters.rateUx,
        redirectUrl: getters.redirectUrl,
      }
    }
  },

  // Options
  autoSave: (s, { scope }) => getOption(scope, 'pnbx:fill:auto-save'),
  multiCompletable: (s, { scope }) => getOption(scope, 'pnbx:fill:multi-completable'),
  privacyPolicy: (s, { scope }) => getOption(scope, 'pnbx:fill:privacy-policy'),
  rateUx: (s, { scope }) => getOption(scope, 'pnbx:fill:rate-ux'),
  redirectUrl: (s, { scope }) => getOption(scope, 'pnbx:fill:redirect-url'),
  autoRedirect: (s, { scope }) => getOption(scope, 'pnbx:fill:auto-redirect'),

  welcomeImage: (s, { scope }) => getOption(scope, 'pnbx:fill:welcome-image'),
  welcomeTitle: (s, { scope }) => getOption(scope, 'pnbx:fill:welcome-title'),
  welcomeStart: (s, { scope }) => getOption(scope, 'pnbx:fill:welcome-start'),
  welcomeMessage: (s, { scope }) => getOption(scope, 'pnbx:fill:welcome-message'),

  completeImage: (s, { scope }) => getOption(scope, 'pnbx:fill:complete-image'),
  completeTitle: (s, { scope }) => getOption(scope, 'pnbx:fill:complete-title'),
  completeMessage: (s, { scope }) => getOption(scope, 'pnbx:fill:complete-message'),
  completeSend: (s, { scope }) => getOption(scope, 'pnbx:fill:complete-send'),
  completeModify: (s, { scope }) => getOption(scope, 'pnbx:fill:complete-modify'),

  incompleteImage: (s, { scope }) => getOption(scope, 'pnbx:fill:incomplete-image'),
  incompleteTitle: (s, { scope }) => getOption(scope, 'pnbx:fill:incomplete-title'),
  incompleteMessage: (s, { scope }) => getOption(scope, 'pnbx:fill:incomplete-message'),
  incompleteAction: (s, { scope }) => getOption(scope, 'pnbx:fill:incomplete-action'),

  endedImage: (s, { scope }) => getOption(scope, 'pnbx:fill:ended-image'),
  endedMessage: (s, { scope }) => getOption(scope, 'pnbx:fill:ended-message'),
  endedTitle: (s, { scope }) => getOption(scope, 'pnbx:fill:ended-title'),
  endedRestart: (s, { scope }) => getOption(scope, 'pnbx:fill:ended-restart'),
  autoSubmit: (s, g, { scope }) => {
    if (g.isMetaMigratedToPages) {
      return !g.submissionPage.enabled
    } else {
      return getOption(scope, 'pnbx:fill:auto-submit')
    }
  },

  customPolicy: (s, { scope }) => ifString(getOption(scope, 'pnbx:fill:custom-policy')),
  showHelpBtn: (s, { scope }) => getOption(scope, 'pnbx:fill:help-visible') !== false,
  helpCustomText: (s, { scope }) => ifString(getOption(scope, 'pnbx:fill:help-text')),
  allowDeclining: (s, { scope }) => getOption(scope, 'pnbx:fill:declinable') !== false,

  confirmationText: (s, { scope }) => getOption(scope, 'pnbx:fill:confirmation-text'),

  companySlug: (state) => state.company?.attributes.slug ?? null,
  flowSlug: (state) => state.flow?.attributes.slug ?? null,

  // Composed getters
  autoCompletable: (state, getters) => getters.steps?.every(isDisabled) ?? false,
  everyCommitted: (state, getters) => getters.steps?.every(isCommitted) ?? false,
  someActive: (state, getters) => getters.steps?.some(isActive) ?? false,
  someCommitted: (state, getters) => getters.steps?.some(isCommitted) ?? false,
  nextStepId: (state, getters) => {
    if (getters.completed) return undefined

    const { steps } = getters
    if (!steps) return undefined

    const activeStepIndex = steps.findIndex(isActive)
    const candidates = activeStepIndex === -1 ? steps : steps.slice(activeStepIndex + 1)
    const activatable = candidates.filter(isActivable)

    const step = activatable.find(isNotCommitted) ?? activatable[0]
    return step?.id
  },
  firstNotCommittedStepId: (state, getters) => {
    if (getters.completed) return undefined

    const { steps } = getters
    if (!steps) return undefined

    const activatable = steps.filter(isActivable)

    const step = activatable.find(isNotCommitted) ?? activatable[0]
    return step?.id
  },
  initialStepId: (state, getters) => {
    if (getters.completed) return undefined
    return getters.steps?.filter(isActivable).find(isNotCommitted)?.id
  },
  firstStepId: (state, getters) => {
    if (getters.completed) return undefined
    return getters.steps?.find(isActivable)?.id
  },
  ended: (state, getters) => getters.loaded && !getters.completed && getters.everyCommitted,
  pendingGhosts: (state, getters) => {
    if (!getters.compiledSteps) return null

    // All the pending data...
    const ghosts = { ...state.pending }

    // ...minus fields visible in some step.
    for (const step of getters.compiledSteps) {
      for (const { key } of step.elements) {
        if (key) if (key in ghosts) delete ghosts[key]
      }
    }

    return ghosts
  },
  entities: (state, getters) =>
    state.flow && state.request
      ? {
          request: state.request,
          flow: state.flow,
          company: state.company ?? null,
          customization: state.customization ?? null,
          response: state.response ?? null,
          branding: state.branding ?? null,
        }
      : null,
  scope: (state, getters) => {
    if (!getters.entities) return undefined
    return createRequestScope(getters.entities, {
      parent: 'legacy',
      overrides: keyedToAttrs({
        ...state.pending,
        ...state.pendingFiles,
      }),
      // Optimization: Using localeFallback instead of localePreferences as
      // it is less likely to change and trigger a re-computation of this
      // getter due to Vue's reactivity. Since localeFallback is computed
      // the same way it would have been by createRequestScope, there is no
      // functional change.
      locales: getters.localeFallback,
    })
  },

  // Optimization: Using computed properties to allow caching these intermediary
  // values that are used to compute localePreferences, effectively allowing to
  // reduce the amount of re-computations of localePreferences.
  localeCurrent: (s) =>
    s.pending['user.locale'] !== undefined
      ? s.pending['user.locale']
      : s.response?.attributes.user?.locale ?? null,
  localesPrevious: (s) => s.responses?.map((r) => r.attributes.user?.locale).filter(Boolean),
  localesBrowser: (s) => (typeof navigator !== 'undefined' ? navigator.languages : undefined),

  localePreferences: (state, getters) => {
    const locales = []

    // First is the "currently selected" locale. This is not needed for
    // computing scope.$locale by createRequestScope but is used to compute the
    // localeFallback.
    const { localeCurrent } = getters
    if (localeCurrent) locales.push(localeCurrent)

    // Then, use previous locales
    const { localesPrevious } = getters
    if (localesPrevious) locales.push(...localesPrevious)

    // Lastly, use browser locales
    const { localesBrowser } = getters
    if (localesBrowser) locales.push(...localesBrowser)

    return locales
  },
  localeFallback: (state, getters) =>
    state.flow
      ? negotiateFlowLocale(state.flow, getters.localePreferences) ||
        state.flow.attributes.locale ||
        undefined
      : undefined,
  locale: (state, getters) =>
    getters.scope?.$locale ||
    getters.localeFallback ||
    getters.localeCurrent ||
    getters.accessLocale ||
    null,
  compiler: (state, getters) => {
    return new FlowCompilerCached()
  },
  stepsCompiler: (state, getters) => {
    return createStepsCompiler({
      path: ['attributes', 'steps'],
      legacy: true,
    })
  },
  stepsBuilder: (state, getters) => {
    return getters.stepsCompiler(state.flow)
  },
  compiledStepsOld: (state, getters) => {
    if (!getters.scope?.$response) return null
    return getters.stepsBuilder?.(getters.scope) ?? null
  },
  compiledSteps: (state, getters) => {
    if (!state.flow) return
    if (!getters.scope) return

    return evalFlowSteps(getters.scope)
  },
  steps: (state, getters) => {
    const evalSteps = evalFlowSteps(getters.scope, {
      legacy: true,
      ignoreInlineExpression: getters.scope.$flow.meta['pnbx:ignore-inline-expression'] ?? false,
    })

    const steps = evalSteps?.map(toStep, { state, getters })
    const everyCommittedExceptLast = steps?.slice(0, -1).every(isCommitted)

    const autoSubmit = getters.isMetaMigratedToPages
      ? !getters.submissionPage.enabled
      : getters.autoSubmit

    if (autoSubmit && steps.length > 0 && everyCommittedExceptLast) {
      const submitButton = steps[steps.length - 1].elements?.find((e) => e.type === 'submit')

      if (submitButton) {
        submitButton.options.type = 'send'
      }
    }
    return steps
  },

  previousResponses: (state, getters) => {
    const { entities } = getters
    if (!entities) return null

    return state.responses?.map((response) => {
      const entries = {
        response,
        request: entities.request,
        flow: entities.flow,
        company: entities.company ?? null,
        customization: entities.customization ?? null,
      }
      const scope = createRequestScope(entries, { parent: 'legacy' })
      const steps = getters.stepsBuilder?.(scope)

      return {
        id: response.id,
        date: response.attributes.completed_at,
        steps: steps?.map(asPreviousResponseStep),
      }
    })
  },

  declinable: (state, getters) => {
    if (getters.completed) return false
    if (getters.declined) return false
    if (state.responses?.length) return false
    if (!getters.authenticated) return false
    if (!getters.allowDeclining) return false

    return true
  },

  activeStep: (state) => state.active,
}

/** @this {{ state: RequestState; getters: any }} */
function toStep({ id, elements, enabled, meta, label }) {
  const { state, getters } = this

  const active = state.active === id

  const stepElements = elements.map(toElement, this)

  const disabled = !enabled
  const dirty = !disabled && stepElements.some(isDirty)
  const committed = (!dirty || disabled) && stepElements.every(isValid)

  return {
    id,
    meta,
    label,
    active: active && !disabled && !getters.completed,
    loading: active && getters.loading,
    dirty,
    disabled,
    committed,
    elements,
  }
}

/** @this {{ state: RequestState; getters: any }} */
function toElement(element) {
  const { state } = this
  const { key } = element

  if (key && !key.includes('.')) {
    console.log('Key does not include .', key)
  }
  return {
    ...element,
    valid: getElementValid.call(this, element),
    touched: key in state.pending || key in state.pendingFiles,
  }
}

/** @this {{ state: RequestState; getters: any }} */
function getElementValid(element) {
  const { error } = element
  if (error) return false

  const { required, value } = element
  if (required && value == null) return false

  const { key } = element
  if (key == null) return true

  if (key.startsWith('user.') || key.startsWith('data.')) {
    const { response } = this.state
    if (!response) return false
    return getValue(response.attributes, key) !== undefined
  }

  if (element.type === 'signature' && required && value != null) {
    if (value.status !== 'signed' && value.status !== 'finished') return false
  }

  return true
}
