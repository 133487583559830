import { isArray, isString } from '@penbox-io/stdlib'
import { IStepDefinitionParser } from '../core/definition'
import { ValueGetterResponse, WorkflowStep, WorkflowStepBaseState } from '../core/step'

export type SignatureWorkflowStep = WorkflowStep & {
  properties: {
    form: []
  }
  state:
    | (WorkflowStepBaseState & {
        send_invitation_at?: Date | null
      })
    | null
}

class Form implements IStepDefinitionParser<SignatureWorkflowStep> {
  parse(step: any, value?: ValueGetterResponse | null): SignatureWorkflowStep {
    return {
      key: isString(step.key) ? step.key : '',
      type: 'signature',
      properties: {
        form: isArray(step.properties?.form) ? step.properties.form : [],
      },
      state: value
        ? {
            status: value.status,
            completed_at: value.completed_at,
            declined_at: value.declined_at,
            owner: value.owner,
            active_until: value.active_until,
            processed_at: value.processed_at,
            link: value.link,
            send_invitation_at: value.send_invitation_at,
            seen_at: value.seen_at,
          }
        : null,
    }
  }
}

export default new Form()
