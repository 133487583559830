import { normalizeError } from '@penbox-io/error-parser'
import {
  contentDispositionEncode,
  contentDispositionFilename,
  ifArray,
  ifString,
  urlEncode,
  asArray,
  objectGet,
  isArray,
  jsonifyStringObject,
} from '@penbox-io/stdlib'
import { usingStable, withGuard, withLock } from '@penbox-io/vuex'

import { evalExpression, findElementById } from '@penbox-io/pen-script'
import {
  byId,
  clearAuthInSession,
  compressFile,
  getAuthFromSession,
  parseAxiosErrorBlobResponse,
  storeAuthInSession,
} from './util'

const ATTRS = ['user', 'data']
function buildAttributes(q) {
  console.log('q', q)
  const attrs = {}
  for (const k of ATTRS) {
    if (q?.[k] != null && typeof q[k] === 'object') {
      attrs[k] = q[k]
    } else {
      attrs[k] = {}
    }
  }

  return attrs
}

/** @typedef {import('./state').State} State */
/** @typedef {import('vuex').Store<State>} Store */
/** @typedef {import('vuex').ActionContext<State, any>} ActionContext */

export default Object.assign(
  {
    start,
    setDeclined,
    loadWithPassword,
    reactivateLink,
  },
  withLock({
    load,
    authenticate,
    create,
  }),
  withGuard(
    {
      goto,
      downloadUrl,
      downloadArchive,
      downloadHttp,
      runSignature,
      loadSignature,
      createSignature,
      deleteSignature,
      execSignature,
      update,
      uploadFile,
      extractFields,
      runApi,
      confirm,
      ...withLock({
        patch,
        publish,
      }),
      ...withLock({
        performPatch,
      }),
    },
    usingStable(({ getters }) => getters.responseId)
  )
)

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function start(ctx) {
  const { requestId } = ctx.getters
  if (!requestId) throw new Error('requestId not specified')

  if (ctx.getters.completed && !ctx.getters.multiCompletable) return

  const user = {
    locale: ctx.getters.locale || this?.$i18n?.locale || undefined,
  }

  // Populate the user object without values already present in the request. We
  // are using scope.user because it contains the user data from both the
  // request and the response, as well as any potential pending values (those
  // should be empty though).
  const { scope = null } = ctx.getters
  if (scope?.user) {
    for (const key in scope.user) {
      if (Object.prototype.hasOwnProperty.call(user, key)) continue

      const value = scope.user[key] ?? null
      if (value !== scope.$request.attributes.user[key]) {
        user[key] = value
      }
    }
  }

  try {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await ctx.dispatch('create', { requestId, attributes: { user } })
  } catch (err) {
    const normalized = normalizeError(err)
    if (normalized?.status === 409 && normalized.code === 'constraint-failed') {
      // Concurrent creation
      return ctx.dispatch('load', { requestId })
    }

    throw err
  }
}

async function createRequestAccess(ctx, requestId, method, options) {
  try {
    // Because this call is not authenticated, we cannot ask to include
    // related entities in the response (pen-core allows to create
    // request_access when not authenticated but that's pretty much it). We
    // will have to do a second (authenticated) call.
    const { data } = await this.$axios.$post(`/core/v1/request_access`, {
      data: {
        attributes: { method, options },
        relationships: { request: { data: { id: requestId || '<invalid>' } } },
      },
    })

    storeAuthInSession(requestId, data.id, data.attributes.$token)

    ctx.commit('LOAD_SUCCESS', { requestId, data })
  } catch (err) {
    const errorObj = normalizeError(err)
    const ignoreInvalidAccess = method === 'auto'

    ctx.commit('LOAD_FAILURE', { requestId, error: errorObj, ignoreInvalidAccess })
    // Prevent loading the request and returning an error
    // if (errorObj?.code === 'request-access-method-invalid') {
    //   return
    // }
    // if (errorObj?.code === 'request-expired' || errorObj?.code === 'request-archived') {
    //   return
    // }
  }
}

async function loadOrCreateRequestAccess(ctx, requestId, method, options, sessionData) {
  try {
    const { data } = await this.$axios.$get(
      `/core/v1/request_access/${sessionData.requestAccessId}`,
      {
        headers: {
          authorization: `Bearer ${sessionData.token}`,
        },
      }
    )

    ctx.commit('LOAD_SUCCESS', { requestId, data })
  } catch (err) {
    // be sure to clear the session to avoid infinite loop
    clearAuthInSession(requestId)
    await createRequestAccess.call(this, ctx, requestId, method, options)
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function load(
  ctx,
  {
    requestId = ctx.getters.requestId,
    initData = {},
    token = ctx.getters.authenticated ? ctx.getters.token : null,
    reload = !token,
    method = reload && ctx.getters.authenticating ? ctx.state.access?.attributes.method : 'auto',
    options = reload && ctx.getters.authenticating ? ctx.state.access?.attributes.options : {},
  } = {}
) {
  if (!requestId) return void ctx.commit('RESET')

  const reAuthenticate = Boolean(reload || !token)

  if (reAuthenticate) {
    ctx.commit('LOAD_REQUEST', { requestId })

    // get the token and the request_access_id from the session
    const sessionData = getAuthFromSession(requestId)

    // if we already have a token, we try to load the request_access
    if (sessionData) {
      await loadOrCreateRequestAccess.call(this, ctx, requestId, method, options, sessionData)
    } else {
      await createRequestAccess.call(this, ctx, requestId, method, options)
    }
  }
  // Do not try to load the request as we in are in an OTP context and it will return 404 anyway
  if (method === 'otp-email' || method === 'otp-sms') return
  const bearer = reAuthenticate ? ctx.getters.token : token
  // Do not even try without bearer
  if (!bearer) return

  ctx.commit('LOAD_REQUEST', { requestId })
  try {
    const { data, included } = await this.$axios.$get(`/core/v1/request_access/find`, {
      headers: {
        'authorization': `Bearer ${bearer}`,
        'cache-control': 'no-cache',
      },
      params: {
        include:
          'request.flow,request.flow_customization.company,request.responses(request),request.branding',
        filter: JSON.stringify({ relationships: { request: { id: { $eq: requestId } } } }),
      },
    })

    // check if we need to update some data on the response
    if (initData && Object.keys(initData).length) {
      const response = included?.find((i) => i.type === 'responses')

      if (response) {
        const attributes = buildAttributes(jsonifyStringObject(initData))

        const updatedResponse = await this.$axios.patch(
          `/core/v1/responses/${response.id}`,
          {
            data: {
              attributes: {
                data: attributes.data,
                user: attributes.user,
              },
            },
          },
          {
            headers: {
              authorization: `Bearer ${bearer}`,
            },
          }
        )

        // update the response attributes
        response.attributes = updatedResponse.data.data.attributes
      }
    }
    ctx.commit('LOAD_SUCCESS', { requestId, data, included })
  } catch (err) {
    ctx.commit('LOAD_FAILURE', {
      requestId,
      error: normalizeError(err),
    })
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function authenticate(ctx, { requestId = ctx.getters.requestId, code = '' } = {}) {
  if (!code || typeof code !== 'string') throw new Error('A code is required to authenticate')

  if (!requestId) return void ctx.commit('RESET')

  const { access } = ctx.state
  if (!access) throw new Error('An access entity is required in order to authenticate')

  ctx.commit('LOAD_REQUEST', { requestId })
  try {
    const { data, included } = await this.$axios.$patch(
      `/core/v1/request_access/${encodeURIComponent(access.id)}`,
      { meta: { 'pnbx:otp:code': code } },
      {
        headers: {
          authorization: `Bearer ${access.attributes.$token}`,
        },
        params: {
          include: 'request.flow,request.flow_customization.company,request.responses(request)',
        },
      }
    )

    storeAuthInSession(requestId, data.id, data.attributes.$token)
    ctx.commit('LOAD_SUCCESS', { requestId, data, included })
  } catch (err) {
    ctx.commit('LOAD_FAILURE', { requestId, error: normalizeError(err) })
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function create(ctx, { requestId = ctx.getters.requestId, attributes = {} } = {}) {
  if (!requestId) throw new Error('requestId not specified')

  ctx.commit('LOAD_REQUEST', { requestId })
  try {
    const { data, included } = await this.$axios.$post(
      '/core/v1/responses',
      { data: { attributes, relationships: { request: { data: { id: requestId } } } } },
      {
        headers: {
          'authorization': `Bearer ${ctx.getters.token}`,
          'x-hapi-tasks': '<sync>',
        },
        params: {
          include: 'request.flow,request.flow_customization.company,request.responses(request)',
        },
      }
    )

    ctx.commit('LOAD_SUCCESS', { requestId, responseId: data.id, data, included })
  } catch (err) {
    ctx.commit('LOAD_FAILURE', { requestId, error: normalizeError(err) })
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function goto(ctx, { stepId }) {
  ctx.commit('SET_CURRENT_STEP', { stepId })
}

/**
 * @param {*} ctx
 * @param {object} payload
 * @param {string} payload.url
 * @param {string} [payload.filename]
 * @param {AbortSignal} [payload.signal]
 * @param {(v?: number) => void} [payload.setPercentage]
 * @returns {Promise<Parameters<typeof import('@penbox-io/browser-download').saveOnDisk>[0]>}
 */
async function downloadUrl(ctx, payload) {
  const url = ifString(payload.url)

  if (url) {
    if (url.startsWith('data:')) return { filename: payload.filename, src: url }

    if (url.startsWith('http:') || url.startsWith('https:')) {
      return ctx.dispatch('downloadHttp', payload).catch((err) => {
        if (url.startsWith('http') || url.startsWith('https:')) {
          return { filename: payload.filename, src: url }
        } else {
          throw err
        }
      })
    }
  }

  const downloadUrl = payload.requestId
    ? urlEncode`/core/v1/requests/${payload.requestId}/exec/download-v2/${payload.dataKey}/${payload.id}`
    : urlEncode`/core/v1/attachments/${payload.id}/exec/download`

  return ctx.dispatch('downloadHttp', { ...payload, url: downloadUrl })
}

async function downloadArchive(ctx, payload) {
  const downloadUrl = urlEncode`/core/v1/requests/${payload.requestId}/exec/download-v2/${payload.dataKey}/compress`

  return ctx.dispatch('downloadHttp', { ...payload, url: downloadUrl })
}

/**
 * @param {*} ctx
 * @param {object} payload
 * @param {string | URL} payload.url
 * @param {string} [payload.filename]
 * @param {AbortSignal} [payload.signal]
 * @param {(v?: number) => void} [payload.setPercentage]
 * @returns {Promise<Parameters<typeof import('@penbox-io/browser-download').saveOnDisk>[0]>}
 */
async function downloadHttp(ctx, { url, filename, signal, setPercentage, setStatus }) {
  setPercentage?.(0)

  const isSameOrigin =
    url instanceof URL
      ? url.origin === window.origin
      : url.startsWith(`${window.origin}/`) || (url.startsWith('/') && !url.startsWith('//'))

  return this.$axios
    .get(url, {
      responseType: 'blob',
      transactionId: false,
      signal,
      headers: isSameOrigin ? { authorization: `Bearer ${ctx.getters.token}` } : {},
      transformRequest: isSameOrigin
        ? undefined
        : [
            (data, headers) => {
              delete headers['X-Requested-With']
              return data
            },
          ],
      onDownloadProgress: (/** @type {ProgressEvent} */ event) => {
        if (event.lengthComputable) {
          setPercentage?.((100 * event.loaded) / event.total)
        } else {
          setPercentage?.(50) // Because why not ?
        }
      },
    })
    .catch(parseAxiosErrorBlobResponse)
    .then((r) => ({
      object: r.data,
      type: r.headers['content-type'],
      name: contentDispositionFilename(r.headers),
      filename,
    }))
}

async function loadSignature(ctx, { signatureId, signal }) {
  const { data } = await this.$axios.$get(urlEncode`/core/v1/signatures/${signatureId}`, {
    signal,
    headers: {
      authorization: `Bearer ${ctx.getters.token}`,
    },
  })

  return data
}

async function createSignature(
  ctx,
  { config: { name, locale, ...config }, element, signal, sync = false, prevSignature }
) {
  if (prevSignature) {
    await ctx.dispatch('deleteSignature', { signatureId: prevSignature.id })
  }

  const { data } = await this.$axios.$post(
    '/core/v1/signatures',
    {
      data: {
        attributes: {
          key: element.key?.replace(/^signatures\./, ''),
          name: ifString(name),
          locale: ifString(locale || this.$i18n.locale),
          config,
        },
        relationships: {
          response: { data: { id: ctx.getters.responseId } },
        },
      },
    },
    {
      signal,
      headers: {
        'authorization': `Bearer ${ctx.getters.token}`,
        // Not a good idea because creating the package might make the HTTP request timeout
        'x-hapi-tasks': sync ? '<sync>' : undefined,
      },
    }
  )

  return data
}

async function deleteSignature(ctx, { signatureId, signal }) {
  return this.$axios.$delete(urlEncode`/core/v1/signatures/${signatureId}`, {
    headers: {
      authorization: `Bearer ${ctx.getters.token}`,
    },
    signal,
  })
}

async function execSignature(ctx, { signatureId, signal }) {
  return this.$axios.$get(urlEncode`/core/v1/signatures/${signatureId}/exec/sign`, {
    headers: {
      authorization: `Bearer ${ctx.getters.token}`,
    },
    signal,
  })
}

/**
 * @param {*} ctx
 * @param {object} payload
 * @param {object} [payload.config]
 * @param {object} [payload.element]
 * @param {boolean} [payload.sync] - If true, the signature will be created synchronously. This will allow the creation to be faster but it can also lead to a timeout if the signature package is too big.
 * @param {AbortSignal} [payload.signal]
 * @param {(v?: number) => void} [payload.setPercentage]
 * @param {(v?: string) => void} [payload.setStatus]
 */
async function runSignature(
  ctx,
  { config, element, signal, setPercentage, setStatus, sync = false }
) {
  // TODO ? reload response data to make sure there isn't a pending signature already ?
  const prevSignatureId = ifString(element.value?.uri)?.match(/^signatures:(.*)$/)?.[1]

  setStatus?.('checking')
  setPercentage?.(1)

  const prevSignature = prevSignatureId
    ? await ctx.dispatch('loadSignature', { signatureId: prevSignatureId, signal }).catch((err) => {
        console.error('Unable to load previous signature', err)
        return null
      })
    : null

  setPercentage?.(5)
  // TODO (?) add support for SSE to get notified when the signature package is
  // ready so that we don't have to choose between sync creation and polling.

  // Dispatch 'publish' event to make the response is up to date
  await ctx.dispatch('publish')

  const signature = await ctx.dispatch('createSignature', {
    config,
    prevSignature,
    element,
    signal,
    sync,
  })

  const CREATE_PERCENTAGE = sync ? 90 : 25

  setStatus?.('creating')
  setPercentage?.(CREATE_PERCENTAGE)

  // Approximation (might need fine tuning): 5 seconds (fix) + 1 seconds per item (variable)
  const AVG_RETRIES = 5 + 1 * (ifArray(config?.items)?.length ?? 0)
  const MAX_RETRIES = 60

  const retries = Math.max(MAX_RETRIES, AVG_RETRIES)

  for (let iteration = 0; iteration < retries; iteration++) {
    try {
      const result = await ctx.dispatch('execSignature', { signatureId: signature.id, signal })

      setStatus?.('finishing')
      setPercentage?.(100)

      return result
    } catch (err) {
      if (signal?.aborted) {
        await ctx.dispatch('deleteSignature', { signatureId: signature.id })
        throw err
      }

      // If we asked for a sync creation, there is no need to poll the server
      if (sync !== false) throw err

      // "Signature package not ready yet" error ?
      if (err?.response?.status !== 409) throw err

      if (iteration > AVG_RETRIES) setStatus?.('finishing')
      setPercentage?.(
        iteration > AVG_RETRIES
          ? undefined // We already reached 100, let's switch to indeterminate
          : CREATE_PERCENTAGE + ((100 - CREATE_PERCENTAGE) / AVG_RETRIES) * iteration
      )

      // Wait before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  }

  throw new Error(`Signature creation still not ready after ${retries} retries`)
}

async function uploadFile(
  ctx,
  { file, element, signal, setPercentage, responseId = ctx.getters.responseId }
) {
  const { blob, name } = await compressFile(file, element)
  const { data: attachment } = await this.$axios.$post(
    urlEncode`/core/v1/responses/${responseId}/exec/upload`,
    blob,
    {
      headers: {
        'authorization': `Bearer ${ctx.getters.token}`,
        'x-pnbx-request-meta': JSON.stringify(element.meta ?? {}),
        'content-type': blob.type || 'application/octet-stream',
        'content-disposition': contentDispositionEncode(name, 'form-data; name="file"'),
      },
      params: {
        // Do not re-download the file data:
        'fields[attachments]': 'name,type,metadata,size',
      },
      signal,
      onUploadProgress: (/** @type {ProgressEvent} */ event) => {
        if (event.lengthComputable) {
          if (event.loaded === event.total) setPercentage(undefined)
          else setPercentage((100 * event.loaded) / event.total)
        }
      },
    }
  )

  return {
    // Standard file properties
    name: attachment.attributes.name,
    type: attachment.attributes.type,
    size: attachment.attributes.size,
    original_name: file.name,
    // pen-script extra properties
    metadata: attachment.attributes.metadata,
    id: attachment.id,
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function update({ commit }, { key = undefined, value = null }) {
  if (!key) return
  commit('SET_PENDING_VALUE', { key, value })
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function confirm(
  { commit, dispatch, getters, state },
  { responseId = getters.responseId, stepId = state.active } = {}
) {
  const step = getters.compiledSteps?.find(byId(stepId))
  if (!step) return // Fool proof

  const attributes = getters.stepAttr(step)

  commit('CONFIRMED_STEP', { stepId: step.id, attributes })

  const { response } = await dispatch('patch', {
    data: { id: responseId, attributes },
    meta: step.meta,
  })

  const { data, user } = response.attributes
  commit('UPDATE_PENDING', { data, user })

  if (getters.autoCompletable) {
    await dispatch('patch', { data: { attributes: { completed_at: new Date() } } })
  }

  return { response }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function publish(
  { commit, dispatch, getters, state },
  { responseId = getters.responseId, stepId = state.active } = {}
) {
  if (getters.autoSave === false) return

  const step = getters.compiledSteps?.find(byId(stepId))
  if (!step) return // Fool proof

  const attributes = getters.stepAttr(step, { withDefaults: false })

  // Optimization (avoid un-necessary PATCH)
  if (!Object.keys(attributes.data).length && !Object.keys(attributes.user).length) return

  await dispatch('performPatch', { data: { id: responseId, attributes } })
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function patch(
  { commit, dispatch, getters, state },
  { data: { id = getters.responseId, attributes, relationships, meta }, meta: ctxMeta }
) {
  const payload = { data: { id, attributes, relationships, meta }, meta: ctxMeta }
  commit('PATCH_REQUEST', payload)
  try {
    const result = await dispatch('performPatch', payload)

    commit('PATCH_SUCCESS', {})
    return result
  } catch (err) {
    commit('PATCH_FAILURE', { error: normalizeError(err) })
    throw err
  }
}

/**
 * @this {Store}
 * @param {ActionContext} ctx
 */
async function performPatch(
  ctx,
  { data: { id = ctx.getters.responseId, attributes, relationships, meta }, meta: ctxMeta }
) {
  const { data: response } = await this.$axios.$patch(
    `/core/v1/responses/${id}`,
    {
      data: { attributes, relationships, meta },
      meta: ctxMeta,
    },
    {
      headers: {
        'authorization': `Bearer ${ctx.getters.token}`,
        'x-hapi-tasks':
          attributes?.completed_at != null || attributes?.declined_at != null
            ? '<sync>'
            : undefined,
      },
    }
  )

  ctx.commit('RESPONSE_UPDATED', { response })

  return { response }
}

/**
 * @param {import('vuex').ActionContext<{}, {}>} context
 * @this {import('vuex').Store}
 */
async function setDeclined(
  { commit, dispatch, state, getters },
  { requestId = state.request.id, value = new Date() } = {}
) {
  if (!requestId) throw new Error('requestId not specified')

  if (!getters.completing || getters.completed) {
    await dispatch('start', { requestId })
  }

  await dispatch('patch', {
    data: {
      attributes: { declined_at: value },
    },
  })
}

async function loadWithPassword({ commit, dispatch, getters }, { username, password }) {
  try {
    commit('START_LOADING')
    const { data } = await this.$axios.$post('/core/v1/request_access/exec/password_auth', {
      username,
      password,
    })
    const requestId = data.relationships.request.data.id
    commit('LOAD_SUCCESS', { requestId, data, force: true })
    return requestId
  } catch (err) {
    commit('LOAD_FAILURE', { error: normalizeError(err), force: true })
  }
}

async function reactivateLink({ commit, dispatch, getters }, data) {
  try {
    commit('START_LOADING')
    const response = await this.$axios.$post(`/core/v1/requests/exec/reactivate`, data)
    commit('END_LOADING')
    return response
  } catch (err) {
    commit('LOAD_FAILURE', { error: normalizeError(err), force: true })
  }
}

/**
 * Based on an attachment id, extract fields from the image
 * Extraction is done in core and based on element.options.extract.fields
 */
async function extractFields({ commit, dispatch, getters }, { element, setPercentage }) {
  function updateFields(fields) {
    if (!fields?.length) return
    for (const field of fields) {
      if (field.key && field.value !== undefined && field.key.includes('.')) {
        if (field.replace === false) {
          // If replace is false, we need to skip if the field is already set
          const currentValue = getters.entities?.response?.attributes?.data?.[field.key]
          if (currentValue !== undefined) continue
        }
        commit('SET_PENDING_VALUE', { key: field.key, value: field.value })
      }
    }
  }

  try {
    if (!element.options?.extract?.fields?.length) return
    const attachmentIds = asArray(element.value).map((a) => a.id)
    const totalFields = element.options.extract.fields.length
    setPercentage(0)
    const url = `/core/v1/attachments/exec/ai/extract?attachments=${JSON.stringify(
      attachmentIds
    )}&fields=${encodeURIComponent(JSON.stringify(element.options.extract.fields))}`

    const source = new EventSource(url)
    const worker = new Promise((resolve) => {
      source.addEventListener('message', function (event) {
        try {
          const data = JSON.parse(event.data)
          if (data.status === 'progress') {
            const fields = data?.data?.entities
            if (!fields) return
            updateFields(fields)
            setPercentage(fields.length / totalFields)
          }
          if (data.status === 'error') {
            console.error('Error packet', data)
          }
          if (data.status === 'done') {
            const fields = data?.data?.entities
            if (!fields?.length) return resolve(data)
            updateFields(fields)
            // Save the full result in <field_name>:extracted
            commit('SET_PENDING_VALUE', {
              key: `${element.key}:extracted`,
              value: { entities: arrayToObject(fields), metadata: { ...data?.data?.metadata } },
            })
            setPercentage(1)
            return resolve(data)
          }
        } catch (err) {
          console.error('Error while extracting fields', err)
        }
      })
      source.addEventListener('end', function () {
        this.close()
      })
    })
    const result = await worker
    await dispatch('publish')
    return result
  } catch (err) {
    console.log('err', err)
    const payload = { errors: Array.from(normalizeError(err)) }

    return payload
  }
}

/**
 * Run API element
 * Call /exec/api on Core api and return the result
 */
async function runApi({ commit, dispatch, getters }, { element }) {
  try {
    // Save pending values so that response is up to date
    await dispatch('publish')

    const result = await this.$axios.$post(
      `/core/v1/responses/exec/api`,
      {
        response_id: getters.responseId,
        element_id: element.id,
      },
      {
        headers: {
          authorization: `Bearer ${getters.token}`,
        },
      }
    )
    let evaluedMapping = null
    if (element?.options?.mapping) {
      const flow = getters.entities.flow
      // Need to take the element before evaluation
      const flowElement = findElementById(flow.attributes.steps, element.id)
      if (flowElement)
        evaluedMapping = handleMapping(flowElement.mapping, result, getters.scope, commit)
    }

    if (evaluedMapping) {
      await dispatch('performPatch', {
        data: {
          id: getters.responseId,
          attributes: { data: evaluedMapping.data, user: evaluedMapping.user },
        },
      })
    } else {
      const key = element.key?.split('.')?.[1] ?? element.key
      await dispatch('performPatch', {
        data: {
          id: getters.responseId,
          attributes: { data: { [key]: result } },
        },
      })
    }

    return result
  } catch (err) {
    return { errors: err?.response?.data?.errors ?? Array.from(normalizeError(err)) }
  }
}

function handleMapping(mapping, result, scope, commit) {
  if (!mapping) return
  scope['@payload'] = result.response
  scope.this = result
  const evaluedMapping = evalExpression(scope, mapping)
  if (!evaluedMapping) return

  function handleKey(key, value) {
    if (value?.value !== undefined) {
      if (value.replace === false) {
        const currentValue = objectGet(scope, key)
        if (currentValue !== undefined && currentValue !== null && currentValue !== '') return
      }
      commit('SET_PENDING_VALUE', { key, value: value.value, force: true })
    } else {
      commit('SET_PENDING_VALUE', { key, value, force: true })
    }
  }
  for (const [key, value] of Object.entries(evaluedMapping)) {
    if (['user', 'data'].includes(key)) {
      for (const [k, v] of Object.entries(value)) {
        handleKey(`${key}.${k}`, v)
      }
    } else {
      handleKey(key, value)
    }
  }

  return evaluedMapping
}
/**
 * Transform an array of [{key, value}] to an object of {[key]: value}
 */
function arrayToObject(array) {
  if (!isArray(array)) return array
  return array.reduce((acc, { key, value }) => {
    acc[key] = value
    return acc
  }, {})
}
